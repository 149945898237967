export const onChatButtonPress = () => show();

export const show = () => window.Intercom && window.Intercom("show");

export const update = (param) =>
  window.Intercom &&
  window.Intercom("update", {
    ...param,
    environment: process.env.GATSBY_RELEASE_ENV || "staging",
  });

export const signOutAndReboot = () => {
  window.Intercom && window.Intercom("shutdown");
  window.Intercom &&
    window.Intercom("boot", {
      app_id: "iklwr1j5",
    });
};
