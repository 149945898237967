import React, { useEffect, useState } from "react";
import { RoughNotation } from "react-rough-notation";
import styled from "@emotion/styled";
import * as colors from "../utils/colors";

export const Button = styled.button`
  // border-radius: 0.8rem;
  // border: none;
  // padding: 0.8rem 1.5rem;
  // font: inherit;
  // outline: inherit;
  // appearance: none;
  // box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.07);
  // font-family: "Poppins", serif;

  // background: ${({ primary, disabled }) =>
    disabled ? `gray` : primary ? colors.GRAPIC_YELLOW : `white`};
  // font-weight: ${({ primary }) => primary && `700`};
  // color: ${({ primary }) => primary && `black`};
  // cursor: ${({ disabled }) => !disabled && `pointer`};

  // &:hover {
  //   transition: ${({ disabled }) => !disabled && `all 0.2s ease`};
  //   transform: ${({ disabled }) => !disabled && `scale(1.01)`};
  // }

  border-radius: 0.8rem;
  border: none;
  padding: 0.8rem 1.5rem;
  font: inherit;
  appearance: none;
  height: 3.2rem;
  box-shadow: ${({ noShadow }) =>
    !noShadow && `1px 4px 10px rgba(0, 0, 0, 0.07);`};
  font-family: "Poppins", serif;
  white-space: nowrap;

  background: ${({ primary, disabled }) =>
    disabled ? `gray` : primary ? colors.GRAPIC_YELLOW : colors.GRAPIC_WHITE};
  font-weight: ${({ primary }) => primary && `600`};
  color: ${({ primary }) => primary && `black`};
  cursor: ${({ disabled }) => !disabled && `pointer`};

  ${({ yellow, inverted }) =>
    yellow &&
    `
  background: ${inverted ? colors.GRAPIC_WHITE : colors.GRAPIC_YELLOW};
  border: ${inverted ? `2px solid ${colors.GRAPIC_YELLOW}` : `none`};
  color: ${inverted ? colors.GRAPIC_YELLOW : colors.GRAPIC_BLACK};
  `}

  ${({ black, inverted }) =>
    black &&
    `
  background: ${inverted ? `unset` : colors.GRAPIC_BLACK};
  border: ${inverted ? `2px solid ${colors.GRAPIC_BLACK}` : `none`};
  color: ${inverted ? colors.GRAPIC_BLACK : colors.GRAPIC_WHITE};

  // @media (prefers-color-scheme: dark) {
  //   background: ${inverted ? `unset` : colors.GRAPIC_WHITE};
  //   border: ${inverted ? `2px solid ${colors.GRAPIC_WHITE}` : `none`};
  //   color: ${inverted ? colors.GRAPIC_WHITE : colors.GRAPIC_BLACK};
  // }
  `}

  &:hover {
    transition: ${({ disabled, noHover }) =>
      !disabled && !noHover && `all 0.2s ease`};
    transform: ${({ disabled, noHover }) =>
      !disabled && !noHover && `scale(1.01)`};
  }
`;

export const TextButton = styled.button`
  border: none;
  background: none;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  padding: 0;
  cursor: pointer;
`;

export const Input = styled.input`
  box-sizing: border-box;
  padding: 1rem;
  height: 3rem;
  border: 1px solid grey;
  border-radius: 0.7rem;
  font-size: initial;
  font-family: "Open Sans", sans;
  font-weight: 500;
  box-shadow: inset 1px 4px 10px rgba(0, 0, 0, 0.03);

  &:read-only {
    filter: brightness(0.95);
  }
`;

export const Paragraph = styled.p`
  font-weight: 300;
  font-size: 1.1rem;
`;

export const RoughNotationWrapper = ({ children, ...props }) => {
  const [hydrated, setHydrated] = useState(false);
  useEffect(() => setHydrated(true), []);
  return hydrated ? (
    <RoughNotation {...props}>{children}</RoughNotation>
  ) : (
    <span css={props.css}>{children}</span>
  );
};
