import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { css } from "@emotion/react";

import { TextButton } from "./atoms";
import * as intercom from "../utils/intercom";

const ContactUs = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            email
          }
        }
      }
    `
  );

  return (
    <>
      <TextButton
        onClick={() => intercom.onChatButtonPress()}
        css={css`
          text-decoration: underline;
        `}
      >{`Chat with us`}</TextButton>
      {` or `}
      <a
        href={`mailto:${site.siteMetadata.email}`}
        target="_blank"
        rel="noopener noreferrer"
        css={css`
          color: unset;
        `}
      >
        {`send us an email`}
      </a>
      {`.`}
    </>
  );
};

export default ContactUs;
